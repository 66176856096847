import React from 'react'
import PropTypes from 'prop-types'
import { Routes } from '@constants'
import Button from '@atoms/Button'
import Typography from '@atoms/Typography'
import CtaNotes from './CtaNotes'

const BottomCta = ({ title, subtitle, button, notes }) => (
  <div className="max-w-6xl mx-auto my-20 px-6 md:px-12 xl:px-6">
    <div className="text-center mx-auto bg-primary-bg-main py-10 rounded-xl section-cta px-6">
      <Typography.H2 color="text-white" component="p" colo>
        {title}
      </Typography.H2>
      <Typography.SUBTEXT color="text-white">{subtitle}</Typography.SUBTEXT>
      <div className="mb-12">
        <a
          href={Routes.APP.USER.SIGN_UP}
          draggable="false"
          rel="noopener noreferrer"
          className="relative w-auto"
        >
          <Button size="large" variant="contained" color="secondary">
            {button}
          </Button>
        </a>
      </div>
      <CtaNotes color="text-white" iconColor="text-white" notes={notes} />
    </div>
  </div>
)

BottomCta.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  button: PropTypes.string,
  notes: PropTypes.arrayOf(PropTypes.string),
}

BottomCta.defaultProps = {
  title: 'Ready to get started?',
  subtitle: 'Send your first outreach campaign with LinkedIn automation today.',
  button: 'Try for free',
  notes: undefined,
}

export default BottomCta
