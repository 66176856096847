import React from 'react'
import { MdOutlineCheck } from 'react-icons/md'
import PropTypes from 'prop-types'

const CtaNotes = ({
  notes = ['Free 7-day trial', 'No credit card required', 'Cancel anytime'],
  color,
  iconColor,
}) => {
  const textClasses = `ml-1 text-xs lg:text-sm leading-5 ${color}`
  const iconClasses = iconColor || color

  return (
    <div className="flex justify-between flex-col md:flex-row md:space-x-5 space-y-5 md:space-y-0 w-full max-w-lg mx-auto">
      {notes &&
        notes.map((note) => (
          <div
            key={note}
            className="flex justify-start md:justify-center items-center"
          >
            <div className="flex-shrink-0">
              <MdOutlineCheck className={iconClasses} />
            </div>
            <p className={textClasses}>{note}</p>
          </div>
        ))}
    </div>
  )
}

CtaNotes.propTypes = {
  color: PropTypes.string,
  iconColor: PropTypes.string,
  notes: PropTypes.arrayOf(PropTypes.string),
}

CtaNotes.defaultProps = {
  color: 'text-gray-700',
  iconColor: 'text-green-600',
  notes: undefined,
}

export default CtaNotes
