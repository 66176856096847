import React from 'react'

const Star = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.55163 1.90849C8.73504 1.53687 9.26496 1.53687 9.44837 1.90849L11.5287 6.1237C11.6015 6.27127 11.7423 6.37356 11.9052 6.39722L16.5569 7.07316C16.967 7.13276 17.1308 7.63674 16.834 7.92601L13.468 11.2071C13.3501 11.322 13.2964 11.4875 13.3242 11.6497L14.1188 16.2826C14.1889 16.6911 13.7601 17.0026 13.3933 16.8097L9.23267 14.6223C9.08701 14.5457 8.91299 14.5457 8.76733 14.6223L4.60667 16.8097C4.23985 17.0026 3.81114 16.6911 3.88119 16.2826L4.67581 11.6497C4.70363 11.4875 4.64985 11.322 4.53201 11.2071L1.16597 7.92601C0.869207 7.63674 1.03296 7.13276 1.44307 7.07316L6.09483 6.39722C6.25769 6.37356 6.39847 6.27127 6.4713 6.1237L8.55163 1.90849Z"
        fill="#FFDE06"
        stroke="#151417"
        strokeWidth="1.5"
      />
    </svg>
  )
}

const Stars = () => (
  <div className="flex items-center gap-1">
    <Star />
    <Star />
    <Star />
    <Star />
    <Star />
  </div>
)

export default Stars
