import React from 'react'

const items = [
  '/marketstar.svg',
  '/wynter.svg',
  '/uptimerobot.svg',
  '/modernoutbound.svg',
  '/asora.svg',
  '/accurity.svg',
]

const Customers = () => (
  <div className="lg:w-3/4 mx-auto mt-12 grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6">
    {items.map((img) => (
      <div key={img} className="p-4 transition duration-200 hover:grayscale-0">
        <img
          src={img}
          className="h-12 w-auto mx-auto"
          loading="lazy"
          alt="client logo"
          width=""
          height=""
        />
      </div>
    ))}
  </div>
)

export default Customers
