import Button from '@atoms/Button'
import Typography from '@atoms/Typography'
import BottomCta from '@components/BottomCta'
import CtaNotes from '@components/CtaNotes'
import Customers from '@components/Customers'
import HelmetHelper from '@components/HelmetHelper'
import Testimonials from '@components/Testimonials'
import Shell from '@components/custom/Shell'
import { Config, Routes } from '@constants'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Link, graphql } from 'gatsby'
import { get } from 'lodash'
import React from 'react'
import { MdFactCheck } from 'react-icons/md'
import { RiMailSendLine } from 'react-icons/ri'
import { TbMessage2, TbReportMoney } from 'react-icons/tb'

const breakpoint = 200

const LINK = {
  demo: Routes.CALENDY,
  sign_up: Routes.APP.USER.SIGN_UP,
}

const benefitsColors = [
  'bg-yellow-400',
  'bg-emerald-400',
  'bg-red-400',
  'bg-blue-400',
]

const benefitIcons = [TbReportMoney, MdFactCheck, RiMailSendLine, TbMessage2]

const LandingPage = (props) => {
  const page = get(props, 'data.contentfulLandingPage')

  const [offset, setOffset] = React.useState(0)

  React.useEffect(() => {
    if (!window) {
      return undefined
    }

    const onScroll = () => setOffset(window.pageYOffset)
    // clean up code
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  const isBreakpointActive = offset > breakpoint

  const benefitsList = get(page, 'benefitsList') || []

  return (
    <div>
      <Shell location={get(props, 'location')} />
      <HelmetHelper title={page.metaTitle} description={page.metaDescription} />
      <header>
        <nav
          className={`z-10 w-full ${
            isBreakpointActive ? 'fixed shadow bg-white' : 'absolute'
          }`}
        >
          <div className="max-w-6xl mx-auto px-6 md:px-12 xl:px-6">
            <div className="flex flex-wrap items-center justify-between py-2 gap-6 md:py-4 md:gap-0 relative">
              <div className="relative z-20 w-full flex items-center justify-between lg:w-max md:px-0 ">
                <a
                  href="#home"
                  aria-label="logo"
                  className="flex space-x-2 items-center "
                >
                  <Link to={Routes.ROOT}>
                    <span className="sr-only">Workflow</span>
                    <img
                      className="h-6 w-full"
                      src="/icereach-text.svg"
                      alt={Config.LOGO_TITLE}
                      title={Config.LOGO_TITLE}
                    />
                  </Link>
                </a>

                {/* <div className="relative flex items-center lg:hidden max-h-10">
                  <label
                    role="button"
                    htmlFor="toggle_nav"
                    aria-label="humburger"
                    id="hamburger"
                    className="relative  p-6 -mr-6 hidden"
                  >
                    <div
                      aria-hidden="true"
                      id="line"
                      className="m-auto h-0.5 w-5 rounded bg-sky-900  transition duration-300"
                    />
                    <div
                      aria-hidden="true"
                      id="line2"
                      className="m-auto mt-2 h-0.5 w-5 rounded bg-sky-900  transition duration-300"
                    />
                  </label>
                </div> */}
              </div>
              <div
                className="flex-col z-20 flex-wrap gap-6 p-8 rounded-3xl border border-gray-100 bg-white shadow-2xl shadow-gray-600/10 justify-end w-full invisible opacity-0 translate-y-1  absolute top-full left-0 transition-all duration-300 scale-95 origin-top
                            lg:relative lg:scale-100 lg:peer-checked:translate-y-0 lg:translate-y-0 lg:flex lg:flex-row lg:items-center lg:gap-0 lg:p-0 lg:bg-transparent lg:w-7/12 lg:visible lg:opacity-100 lg:border-none
                            peer-checked:scale-100 peer-checked:opacity-100 peer-checked:visible lg:shadow-none space-x-3"
              >
                <div className="mt-12 lg:mt-0">
                  <a
                    href={LINK.demo}
                    target="_blank"
                    rel="noopenner noreferrer nofollow"
                  >
                    <Button variant="outlined" size="small">
                      {page.navbarCtaSecondary}
                    </Button>
                  </a>
                </div>

                <div className="mt-12 lg:mt-0">
                  <a href={LINK.sign_up}>
                    <Button variant="contained" size="small">
                      {page.navbarCtaPrimary}
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <main>
        <div className="bg-hero">
          <div className="max-w-6xl mx-auto px-6 md:px-12 xl:px-6 pb-8">
            <div className="relative pt-36 ml-auto">
              <div className="lg:w-8/12 text-center mx-auto">
                <h1 className="text-gray-900 font-bold text-5xl md:text-6xl xl:text-7xl leading-tight">
                  {page.title}
                </h1>
                {!!page.subtitle && (
                  <p className="lg:w-4/5 mx-auto mt-8 text-gray-700 text-lg">
                    {documentToReactComponents(JSON.parse(page.subtitle.raw))}
                  </p>
                )}
                <div className="mt-14 flex flex-wrap justify-center gap-y-4 gap-x-6">
                  <a href={Routes.APP.USER.SIGN_UP}>
                    <Button size="large" variant="contained">
                      {page.mainCtaButton}
                    </Button>
                  </a>
                </div>
                <div className="mt-7">
                  <CtaNotes notes={page.mainCtaNotes} />
                </div>
              </div>
              <Customers />
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6 my-20">
          <div className="text-center">
            <Typography.H2>{page.benefitsTitle}</Typography.H2>
            <div className="grid-cols-1  md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 grid grid-flow-row gap-20 mt-12 pb-5">
              {benefitsList.map((item, index) => {
                const Icon = benefitIcons[index]
                return (
                  <div
                    key={item}
                    className="flex items-center flex-col space-y-6"
                  >
                    <div
                      className={`${benefitsColors[index]} rounded list-item-icon`}
                    >
                      <Icon className="w-8 h-auto text-white" />
                    </div>
                    <h3 className="text-base md:text-xl font-semibold leading-6">
                      {item}
                    </h3>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6 my-20 pb-10">
          <Testimonials
            title={page.testimonialTitle}
            subtitle={page.testimonialSubtitle}
          />
        </div>

        <div className="">
          <BottomCta
            title={page.bottomCtaTitle}
            subtitle={page.bottomCtaSubtitle}
            button={page.bottomCtaButton}
            notes={page.bottomCtaNotes}
          />
        </div>
      </main>

      <footer className="max-w-6xl mx-auto px-6 md:px-12 xl:px-6 my-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4 text-gray-700 text-xs">
            <Link
              className="hover:underline transition-all duration-150"
              to={Routes.PRICING}
            >
              Pricing
            </Link>
            <div>|</div>
            <Link
              className="hover:underline transition-all duration-150"
              to={Routes.LEGAL.PRIVACY_POLICY}
            >
              Privacy policy
            </Link>
            <div>|</div>
            <Link
              className="hover:underline transition-all duration-150"
              to={Routes.LEGAL.GDPR}
            >
              GDPR
            </Link>
          </div>

          <div>
            <div className="text-gray-700 text-xs">{Config.NAME} © 2022</div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default LandingPage

export const pageQuery = graphql`
  query LandingPageBySlug($slug: String!) {
    contentfulLandingPage(slug: { eq: $slug }) {
      slug
      metaTitle
      metaDescription
      navbarCtaPrimary
      navbarCtaSecondary
      subtitle {
        raw
      }
      title
      mainCtaButton
      mainCtaNotes
      benefitsTitle
      benefitsList
      testimonialTitle
      testimonialSubtitle
      bottomCtaTitle
      bottomCtaSubtitle
      bottomCtaButton
      bottomCtaNotes
    }
  }
`
